import axios from 'axios'
import * as Sentry from '@sentry/nextjs'
import * as FirebaseUtils from 'utils/backend/sdk/firebase'

const api = axios.create({
    baseURL: process.env.NEXT_PUBLIC_API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
})

const handleError = (e: unknown) => {
    if (axios.isAxiosError(e)) {
        if (e.response) {
            console.log(e.response.data, e.response.status)
            if (e.response.status === 401) FirebaseUtils.RefreshFbToken()
            Sentry.captureException(e)
            return e
        } else if (e.request) {
            console.log('Errore nella richiesta:', e.message)
            console.log(e)
            Sentry.captureException(e)
            return 'Errore nella richiesta'
        } else {
            Sentry.captureException(e)
            console.log('Errore di setup:', e.message)
            return 'Errore di setup'
        }
    } else if (e instanceof Error) {
        Sentry.captureException(e)
        console.error('Errore sconosciuto:', e.message)
        return 'Errore sconosciuto'
    } else return e
}

export const getApp = async (appId: string): Promise<GetAppDataResponse | ResponseError> => {
    try {
        const res = await api.get(`/apps/${appId}`)
        return res.data.data
    } catch (e) {
        throw handleError(e)
    }
}

// Auth

export const setAuthToken = (token: string | null) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`
}
export const removeAuthToken = () => {
    api.defaults.headers.common.Authorization = ``
}

export const exchangeFirebaseToken = async (firebaseToken: string): Promise<string | ResponseError> => {
    try {
        const res = await api.post('/auth/token', {
            token: firebaseToken,
        })
        return res.data.data
    } catch (e) {
        throw handleError(e)
    }
}

// Users

export const createUser = async (params: CreateUserParams): Promise<void | ResponseError> => {
    try {
        await api.post('/users', params)
    } catch (e) {
        throw handleError(e)
    }
}

export const searchUsers = async (search: string): Promise<SearchUsersResponse | ResponseError> => {
    try {
        const res = await api.get(`/users?search=${search}`)
        return res.data.data
    } catch (e) {
        throw handleError(e)
    }
}

export const getUserWorkspacesForApp = async (
    userId: string,
    APP_ID: string,
): Promise<GetUserWorkspacesForAppResponse | ResponseError> => {
    try {
        const res = await api.get(`users/${userId}/apps/${APP_ID}/workspaces`)
        return res.data.data
    } catch (e) {
        throw handleError(e)
    }
}

export const getUserById = async (userId: string): Promise<User | ResponseError> => {
    try {
        const res = await api.get(`/users/${userId}`)
        return res.data.data
    } catch (e) {
        throw handleError(e)
    }
}

export const checkUserExistance = async (params: checkUserExistanceParams): Promise<void | ResponseError> => {
    try {
        await api.post('/users/existence-check', params)
    } catch (e) {
        throw handleError(e)
    }
}

export const updateUser = async (
    userId: string,
    name?: string,
    phoneNumber?: string,
    username?: string,
): Promise<void | ResponseError> => {
    try {
        await api.patch(`/users/${userId}`, {
            name,
            phone_number: phoneNumber,
            username,
        })
    } catch (e) {
        throw handleError(e)
    }
}

// Workspaces

export const getUserWorkspaces = async (userId: string | undefined): Promise<GetWorkspaceResponse | ResponseError> => {
    try {
        const res = await api.get(`/users/${userId}/workspaces`)
        return res.data.data
    } catch (e) {
        throw handleError(e)
    }
}

export const createUserWorkspace = async (
    userId: string,
    workspaceId?: string,
    workspaceCode?: string,
): Promise<JoinWorkspaceResponse | ResponseError> => {
    try {
        const res = await api.post(`/users/${userId}/workspaces`, {
            app_id: process.env.NEXT_PUBLIC_APP_ID,
            workspace_id: workspaceId,
            workspace_code: workspaceCode,
        })
        return res.data.data
    } catch (e) {
        throw handleError(e)
    }
}

export const getWorkspace = async (workspaceId: string): Promise<Workspace | ResponseError> => {
    try {
        const res = await api.get(`/workspaces/${workspaceId}`)
        return res.data.data
    } catch (e) {
        throw handleError(e)
    }
}

export const updateUserWorkspace = async (
    params: UpdateUserWorkspaceParams,
): Promise<UpdateUserWorkspaceResponse | ResponseError> => {
    try {
        const res = await api.patch(`/users/${params.userId}/workspaces/${params.userWorkspaceId}`, {
            exam: {
                _id: params.examId,
                default: false,
                institution: params.institutionId,
                first_time_prepared: params.first_time_prepared,
            },
        })
        return res.data.data
    } catch (e) {
        throw handleError(e)
    }
}

// Exams and Institutions

export const getIstitution = async (workspaceId: string): Promise<Institution | ResponseError> => {
    try {
        const res = await api.get(`/institutions?workspace=${workspaceId}`)
        return res.data.data
    } catch (e) {
        throw handleError(e)
    }
}

export const getExams = async (userId: string) => {
    try {
        const res = await api.get(`/users/${userId}/istitution`)
        return res.data.data
    } catch (e) {
        throw handleError(e)
    }
}

export const listExamsOfAWorkspace = async (
    institutionId: string,
    workspaceId: string,
): Promise<Exam | ResponseError> => {
    try {
        const res = await api.get(`/exams?institution=${institutionId}&workspace=${workspaceId}`)
        return res.data.data
    } catch (e) {
        throw handleError(e)
    }
}

// Simulations

export const createSimulation = async (
    simulationParams: CreateSimulationParams,
): Promise<Simulation | ResponseError> => {
    try {
        const res = await api.post(`/users/${simulationParams.uid}/simulations`, {
            workspace: simulationParams.workspaceId,
            exam: simulationParams.examId,
            mode: simulationParams.mode,
            type: simulationParams.type,
            simulation: simulationParams.simulationId,
            structure: simulationParams.structureId,
        })
        return res.data.data as Simulation
    } catch (e) {
        throw handleError(e)
    }
}

export const completeSimulation = async (
    userId: string,
    simulationId: string,
    answers: AnswerToSave[],
    time: number,
    mode: string,
) => {
    try {
        const res = await api.put(`/users/${userId}/simulations/${simulationId}`, {
            answers: answers,
            time: time,
            mode: mode,
        })
        return res.data.data
    } catch (e) {
        throw handleError(e)
    }
}

export const listSimulations = async (userId: string, workspaceId: string, exam: string) => {
    try {
        const res = await api.get(`/users/${userId}/simulations?workspace=${workspaceId}&exam=${exam}`)
        return res.data.data
    } catch (e) {
        throw handleError(e)
    }
}

export const listExamSimulations = async (examId: string, workspaceId: string) => {
    try {
        const res = await api.get(`/exams/${examId}/simulations?limit=11&count_total=true&workspace=${workspaceId}`)
        return res.data.data
    } catch (e) {
        throw handleError(e)
    }
}

export const listExamStructures = async (userWorkspaceId: string) => {
    try {
        const res = await api.get(`/simulation-structures?workspace=${userWorkspaceId}&expand=sections.topics`)

        return res.data.data
    } catch (e) {
        throw handleError(e)
    }
}

export const getExamStructure = async (structureId: string) => {
    try {
        const res = await api.get(`/simulation-structures/${structureId}?expand=sections.topics`)
        return res.data.data
    } catch (e) {
        throw handleError(e)
    }
}

export const getSimulation = async (userId: string, simulationId: string) => {
    try {
        const res = await api.get(`/users/${userId}/simulations/${simulationId}`)

        return res.data.data
    } catch (e) {
        throw handleError(e)
    }
}

export const getSimulationStats = async (userId: string, examId: string, userWorkspaceId: string) => {
    try {
        const res = await api.get(`/users/${userId}/simulations/stats?exam=${examId}&workspace=${userWorkspaceId}`)
        return res.data.data
    } catch (e) {
        throw handleError(e)
    }
}

export const deleteSimulation = async (userId: string, simulationId: string) => {
    try {
        const res = await api.delete(`/users/${userId}/simulations/${simulationId}`)
        return res.data.data
    } catch (e) {
        throw handleError(e)
    }
}

// Quizzes

export const createQuiz = async (userId: string, data: CreationQuizBody) => {
    try {
        const res = await api.post(`/users/${userId}/quizzes`, { ...data })
        return res.data.data
    } catch (e) {
        throw handleError(e)
    }
}

export const completeQuiz = async (userId: string, quizId: string, answers: AnswerToSave[], time: number) => {
    try {
        const res = await api.put(`/users/${userId}/quizzes/${quizId}`, {
            answers: answers,
            time: time,
        })
        return res.data.data
    } catch (e) {
        throw handleError(e)
    }
}

export const getQuizzes = async (userId: string, examId: string, userWorkspaceId: string) => {
    try {
        const res = await api.get(`/users/${userId}/quizzes?exam=${examId}&workspace=${userWorkspaceId}`)
        return res.data.data
    } catch (e) {
        throw handleError(e)
    }
}

export const getQuiz = async (userId: string, quizId: string) => {
    try {
        const res = await api.get(`/users/${userId}/quizzes/${quizId}`)
        return res.data.data
    } catch (e) {
        throw handleError(e)
    }
}

export const deleteQuiz = async (userId: string, quizId: string) => {
    try {
        const res = await api.delete(`/users/${userId}/quizzes/${quizId}`)
        return res.data.data
    } catch (e) {
        throw handleError(e)
    }
}

export const getQuizStats = async (
    userId: string,
    examId: string,
    workspaceId: string,
): Promise<QuizStats | ResponseError> => {
    try {
        const res = await api.get(`/users/${userId}/quizzes/stats/topics?exam=${examId}&workspace=${workspaceId}`)
        return res.data.data
    } catch (e) {
        throw handleError(e)
    }
}

export const getQuizTopicStats = async (
    userId: string,
    examId: string,
    userWorkspaceId: string,
    topicId: string,
): Promise<TopicsQuizStats | ResponseError> => {
    try {
        const res = await api.get(
            `/users/${userId}/quizzes/stats/topics/${topicId}?exam=${examId}&workspace=${userWorkspaceId}`,
        )
        return res.data.data
    } catch (e) {
        throw handleError(e)
    }
}

// Topics

export const getExamTopics = async (
    examId: string,
    datasetId: string,
    userWorkspaceId: string,
): Promise<ExamTopics | ResponseError> => {
    try {
        const res = await api.get(`/exams/${examId}/topics?dataset=${datasetId}&workspace=${userWorkspaceId}`)
        return res.data.data
    } catch (e) {
        throw handleError(e)
    }
}

export const getExamDatasets = async (examId: string) => {
    try {
        const res = await api.get(`/exams/${examId}/datasets`)
        return res.data.data
    } catch (e) {
        throw handleError(e)
    }
}

// Subscriptions

export const getUserWorkspaceSubscriptions = async (
    userId: string,
    workspaceId: string,
): Promise<GetRemaingAvailabilityResponse | ResponseError> => {
    try {
        const res = await api.get(`/users/${userId}/workspaces/${workspaceId}/subscriptions`)
        return res.data.data
    } catch (e) {
        throw handleError(e)
    }
}

export const getUserWorkspaceSubscriptionsPlan = async (
    userId: string,
    workspaceId: string,
): Promise<GetRemaingAvailabilityResponse | ResponseError> => {
    try {
        const res = await api.get(`/users/${userId}/workspaces/${workspaceId}/subscriptions/plan-available`)
        return res.data.data
    } catch (e) {
        throw handleError(e)
    }
}

// Challenges

export const createChallenge = async (
    userId: string,
    type: 'RANDOM' | 'FRIENDLY',
    workspace: string,
    exam: string,
    opponent?: string,
) => {
    try {
        const res = await api.post(`/users/${userId}/challenges`, {
            type,
            workspace,
            exam,
            opponent,
        })
        return res.data.data
    } catch (e) {
        throw handleError(e)
    }
}

export const getChallengesByType = async (
    userId: string,
    type: 'INVITES' | 'COMPLETED' | 'IN_PROGRESS',
    examId: string,
    workspaceId: string,
): Promise<GetChallengesByTypeResponse | ResponseError> => {
    try {
        const res = await api.get(`/users/${userId}/challenges?type=${type}&exam=${examId}&workspace=${workspaceId}`)
        return res.data.data.results
    } catch (e) {
        throw handleError(e)
    }
}

export const getChallenge = async (userId: string, challengeId: string) => {
    try {
        const res = await api.get(`/users/${userId}/challenges/${challengeId}`)
        return res.data.data
    } catch (e) {
        throw handleError(e)
    }
}

export const completeChallenge = async (userId: string, challengeId: string, answersSaved: AnswerToSave[]) => {
    try {
        const res = await api.put(`/users/${userId}/challenges/${challengeId}`, { answers: answersSaved })
        return res.data.data
    } catch (e) {
        throw handleError(e)
    }
}

export const deleteChallenge = async (userId: string, challengeId: string) => {
    try {
        await api.delete(`/users/${userId}/challenges/${challengeId}`)
    } catch (e) {
        throw handleError(e)
    }
}

export const getChallengeRankings = async (examId: string, workspaceId: string, userId: string) => {
    try {
        const res = await api.get(`/users/${userId}/challenge-rankings?exam=${examId}&workspace=${workspaceId}`)

        return res.data.data
    } catch (e) {
        throw handleError(e)
    }
}
