import * as React from 'react'
import * as TestySdk from 'utils/backend/sdk/testy-web-sdk'
import useSWR from 'swr'

import { AuthContext } from 'components/providers/auth-provider'
import { UserContext } from 'components/providers/context/user-context'

export const useAppData = (): SWRResponse<GetAppDataResponse | undefined> => {
    const fetcher = () => TestySdk.getApp(process.env.NEXT_PUBLIC_APP_ID as string)

    const { data, error, mutate } = useSWR<GetAppDataResponse>(`/app`, fetcher)

    const loading = !data && !error

    return { data, loading, error, mutate }
}

// Users

export const useUser = (userId: string): SWRResponse<User | undefined> => {
    const shoudFetch = !!userId

    const fetcher = () => TestySdk.getUserById(userId)

    const { data, error, mutate } = useSWR<User>(shoudFetch ? `/users/${userId}` : null, fetcher)

    const loading = !data && !error

    return { data, loading, error, mutate }
}

export const useSearchUsers = (search: string): SWRResponse<SearchUsersResponse | undefined> => {
    const shouldFetch = search !== ''

    const fetcher = () => TestySdk.searchUsers(search)

    const { data, error, mutate } = useSWR<SearchUsersResponse>(shouldFetch ? `/users/${search}` : null, fetcher)

    const loading = !data && !error

    return { data, loading, error, mutate }
}

// Workspaces

export const useGetUserWorspacesForApp = (): SWRResponse<GetUserWorkspacesForAppResponse[] | undefined> => {
    const { authData } = React.useContext(AuthContext)

    const shouldFetch = authData?.userUid && authData?.userUid !== 'null'

    const userUid = authData?.userUid || ''

    const fetcher = () => TestySdk.getUserWorkspacesForApp(userUid, process.env.NEXT_PUBLIC_APP_ID as string)

    const { data, error, mutate } = useSWR<GetUserWorkspacesForAppResponse[]>(
        shouldFetch ? `users/${authData?.userUid}/apps/${process.env.NEXT_PUBLIC_APP_ID}/workspaces/` : null,
        fetcher,
    )

    const loading = !data && !error

    return { data, loading, error, mutate }
}

export const useUserWorkspaces = (): SWRResponse<GetWorkspaceResponse['results'] | undefined> => {
    const { authData } = React.useContext(AuthContext)

    const userUid = authData?.userUid || ''
    const shoudFetch = authData && authData?.userUid !== null

    const fetcher = () => TestySdk.getUserWorkspaces(userUid).then((res) => res.results)

    const { data, error, mutate } = useSWR<GetWorkspaceResponse['results']>(
        shoudFetch ? `/users/${authData.userUid}/workspaces` : null,
        fetcher,
    )

    const loading = !data && !error

    return { data, loading, error, mutate }
}

export const useWorkspace = (workspaceId: string): SWRResponse<Workspace | undefined> => {
    const { authData } = React.useContext(AuthContext)
    const shoudFetch = workspaceId && workspaceId !== '' && authData?.userUid

    const fetcher = () => TestySdk.getWorkspace(workspaceId)

    const { data, error, mutate } = useSWR<Workspace>(shoudFetch ? `/workspace/${workspaceId}` : null, fetcher)

    const loading = !data && !error

    return { data, loading, error, mutate }
}

// Exams and Institutions

export const useWorkspaceExams = (institutionId: string, propsWorkspace?: string): SWRResponse<Exam[] | undefined> => {
    const user = React.useContext(UserContext)
    const workspace = propsWorkspace ? propsWorkspace : user.userData?.appWorkspace

    const shouldFetch = workspace !== '' && institutionId

    const fetcher = () => TestySdk.listExamsOfAWorkspace(institutionId, workspace || '')

    const { data, error, mutate } = useSWR<Exam[]>(
        shouldFetch ? `/exams?institution=${institutionId}&workspace=${workspace}` : null,
        fetcher,
    )

    const loading = !data && !error
    return { data, loading, error, mutate }
}

export const useInstitution = (propsWorkspace?: string): SWRResponse<Institution[] | undefined> => {
    const user = React.useContext(UserContext)
    const workspace = propsWorkspace ? propsWorkspace : user.userData?.appWorkspace

    const shouldFetch = workspace !== ''

    const fetcher = () => TestySdk.getIstitution(workspace || '')

    const { data, error } = useSWR<Institution[]>(shouldFetch ? `/institutions?workspace=${workspace}` : null, fetcher)

    const loading = !data && !error
    return { data, loading, error }
}

// Simulations

export const useExamSimulations = (): SWRResponse<ExamSimulation[] | undefined> => {
    const user = React.useContext(UserContext)
    const examId = user.userData?.examId
    const userWorkspaceId = user.userData?.workspaceId

    const shoudFetch = examId && userWorkspaceId

    const fetcher = () => TestySdk.listExamSimulations(examId || '', userWorkspaceId || '').then((res) => res.results)

    const { data, error, mutate } = useSWR<ExamSimulation[]>(
        shoudFetch ? `/exams/${examId}/simulations?count_total=true&workspace=${userWorkspaceId}` : null,
        fetcher,
    )

    const loading = !data && !error

    return { data: data || [], loading, error, mutate }
}

export const useStructureSimulations = (): SWRResponse<Structure[] | undefined> => {
    const { userData } = React.useContext(UserContext)
    const workspaceId = userData?.appWorkspace

    const shoudFetch = !!workspaceId

    const fetcher = () => TestySdk.listExamStructures(workspaceId || '').then((res) => res)

    const { data, error, mutate } = useSWR<Structure[]>(
        shoudFetch ? `/simulations-structures/${workspaceId}&section.topics` : null,
        fetcher,
    )
    const loading = !data && !error

    return { data: data || [], loading, error, mutate }
}

export const useStructure = (structureId: string): SWRResponse<Structure | undefined> => {
    const shoudFetch = !!structureId && structureId !== ''

    const fetcher = () => TestySdk.getExamStructure(structureId || '').then((res) => res)

    const { data, error, mutate } = useSWR<Structure>(
        shoudFetch ? `/simulations-structures/${structureId}` : null,
        fetcher,
    )
    const loading = !data && !error

    return { data, loading, error, mutate }
}

export const useSimulations = (): SWRResponse<Simulation[] | undefined> => {
    const { userData } = React.useContext(UserContext)
    const { authData } = React.useContext(AuthContext)

    const shoudFetch = userData?.examId && userData?.workspaceId && authData?.userUid
    const fetcher = () =>
        TestySdk.listSimulations(authData?.userUid || '', userData?.workspaceId || '', userData?.examId || '')

    const { data, error, mutate } = useSWR<Simulation[]>(
        shoudFetch
            ? `/users/${authData.userUid}/simulations?workspace=${userData.workspaceId}&exam=${userData.examId}`
            : null,
        fetcher,
    )

    const loading = !data && !error

    return { data, loading, error, mutate }
}

export const useSimulation = (
    simulationId: string,
    refetch = false,
    shouldFetch = true,
): SWRResponse<Simulation | undefined> => {
    if (!simulationId) throw new Error('simulationId is required')
    const { authData } = React.useContext(AuthContext)

    const _shouldFetch = authData?.userUid && shouldFetch && simulationId

    const fetcher = () => TestySdk.getSimulation(authData?.userUid || '', simulationId)

    const { data, error, mutate } = useSWR<Simulation>(
        _shouldFetch ? `/users/${authData.userUid}/simulations/${simulationId}/${refetch}` : null,
        fetcher,
    )

    const loading = !data && !error

    return { data, loading, error, mutate }
}

export const useSimulationStats = (): SWRResponse<SimulationStats | undefined> => {
    const { userData } = React.useContext(UserContext)
    const { authData } = React.useContext(AuthContext)
    const examId = userData?.examId
    const userWorkspaceId = userData?.workspaceId || ''

    const shoudFetch = userData

    const fetcher = () => TestySdk.getSimulationStats(authData?.userUid || '', examId || '', userWorkspaceId)

    const { data, error } = useSWR<SimulationStats>(
        shoudFetch ? `/users/${authData?.userUid}/simulations/stats?exam=${examId}&workspace=${userWorkspaceId}` : null,
        fetcher,
    )

    const loading = !data && !error

    return { data, loading, error }
}

// Credits

export const useCredits = (): SWRResponse<GetRemaingAvailabilityResponse | undefined> => {
    const { userData } = React.useContext(UserContext)
    const { authData } = React.useContext(AuthContext)
    const userWorkspaceId = userData?.workspaceId || ''

    const shouldFetch = authData?.userUid && userData?.workspaceId

    const fetcher = () => TestySdk.getUserWorkspaceSubscriptions(authData?.userUid || '', userWorkspaceId)

    const { data, error, mutate } = useSWR<GetRemaingAvailabilityResponse>(
        shouldFetch ? `/users/${authData.userUid}/workspaces/${userWorkspaceId}/subscriptions` : null,
        fetcher,
    )

    const loading = !data && !error

    return {
        data,
        loading,
        error,
        mutate,
    }
}

export const useCreditsAvailable = (): SWRResponse<GetRemaingAvailabilityResponse | undefined> => {
    const { authData } = React.useContext(AuthContext)
    const { userData } = React.useContext(UserContext)
    const userWorkspaceId = userData?.workspaceId || ''

    const shouldFetch = authData?.userUid && userData?.workspaceId

    const fetcher = () => TestySdk.getUserWorkspaceSubscriptionsPlan(authData?.userUid || '', userWorkspaceId)

    const { data, error } = useSWR<GetRemaingAvailabilityResponse>(
        shouldFetch ? `/users/${authData.userUid}/workspaces/${userWorkspaceId}/subscriptions/plan-available` : null,
        fetcher,
    )

    const loading = !data && !error

    return {
        data,
        loading,
        error,
    }
}

// Quizzes

export const useQuiz = (quizId: string): SWRResponse<Quiz | undefined> => {
    const { authData } = React.useContext(AuthContext)
    const fetcher = () => TestySdk.getQuiz(authData?.userUid || '', quizId)

    const shouldFetch = !!quizId

    const { data, error, mutate } = useSWR<Quiz>(
        shouldFetch ? `/users/${authData?.userUid}/quizzes/${quizId}` : null,
        fetcher,
    )

    const loading = !data && !error

    return { data, loading, error, mutate }
}

export const useExamTopics = (datasetId: string): SWRResponse<ExamTopics | undefined> => {
    const user = React.useContext(UserContext)
    const workspaceId = user.userData?.workspaceId
    const examId = user.userData?.examId

    const shoudFetch = examId

    const fetcher = () => TestySdk.getExamTopics(examId || '', datasetId, workspaceId || '')

    const { data, error } = useSWR<ExamTopics>(
        shoudFetch ? `/exams/${examId}/topics?dataset=${datasetId}&workspace=${workspaceId}` : null,
        fetcher,
    )

    const loading = !data && !error

    return { data, loading, error }
}

export const useQuizStats = (): SWRResponse<QuizStats | undefined> => {
    const { userData } = React.useContext(UserContext)
    const { authData } = React.useContext(AuthContext)

    const shoudFetch = !!userData?.examId && !!userData?.workspaceId

    const fetcher = () =>
        TestySdk.getQuizStats(authData?.userUid || '', userData?.examId || '', userData?.workspaceId || '')

    const { data, error } = useSWR<QuizStats>(
        shoudFetch
            ? `/users/${authData?.userUid}/quizzes/stats/topics?exam=${userData?.examId}&workspace=${userData?.workspaceId}`
            : null,
        fetcher,
    )

    const loading = !data && !error

    return { data, loading, error }
}

export const useQuizTopicsStats = (topicId: string): SWRResponse<TopicsQuizStats | undefined> => {
    const { userData } = React.useContext(UserContext)
    const { authData } = React.useContext(AuthContext)
    const workspaceId = userData?.workspaceId || ''
    const examId = userData?.examId

    const shoudFetch = !!examId && !!workspaceId

    const fetcher = () => TestySdk.getQuizTopicStats(authData?.userUid || '', examId || '', workspaceId || '', topicId)
    const { data, error } = useSWR<TopicsQuizStats>(
        shoudFetch
            ? `/users/${authData?.userUid}/quizzes/stats/topics/${topicId}?exam=${examId}&workspace=${workspaceId}`
            : null,
        fetcher,
    )
    const loading = !data && !error

    return { data, loading, error }
}

export const useQuizzes = (): SWRResponse<Quiz[] | undefined> => {
    const { userData } = React.useContext(UserContext)
    const { authData } = React.useContext(AuthContext)

    const fetcher = () =>
        TestySdk.getQuizzes(authData?.userUid || '', userData?.examId || '', userData?.workspaceId || '')

    const { data, error, mutate } = useSWR<Quiz[]>(
        `/users/${authData?.userUid}/quizzes?exam=${userData?.examId}&workspace=${userData?.examId}`,
        fetcher,
    )

    const loading = !data && !error
    return { data, loading, error, mutate }
}

export const useExamDatasets = (): SWRResponse<ExamDatasetsResponse | undefined> => {
    const { userData } = React.useContext(UserContext)
    const examId = userData?.examId

    const _shouldFetch = !!examId

    const fetcher = () => TestySdk.getExamDatasets(examId as string)

    const { data, error, mutate } = useSWR<ExamDatasetsResponse>(
        _shouldFetch ? `/exams/${examId}/datasets` : null,
        fetcher,
    )

    const loading = !data && !error && _shouldFetch

    return { data, loading, error, mutate }
}

export const useExamDatasests = (examId: string): SWRResponse<ExamDatasetsResponse | undefined> => {
    if (!examId) throw new Error('ExamId is required')

    const fetcher = () => TestySdk.getExamDatasets(examId)

    const { data, error, mutate } = useSWR<ExamDatasetsResponse>(`/exams/${examId}/datasets`, fetcher)

    const loading = !data && !error

    return { data, loading, error, mutate }
}

// Challenges

export const useChallenges = (type: 'INVITES' | 'IN_PROGRESS' | 'COMPLETED'): SWRResponse<Challenge[] | undefined> => {
    const { userData } = React.useContext(UserContext)
    const { authData } = React.useContext(AuthContext)

    const shoudFetch = userData?.appWorkspace && userData.examId

    const fetcher = () =>
        TestySdk.getChallengesByType(
            authData?.userUid || '',
            type,
            userData?.examId || '',
            userData?.appWorkspace || '',
        )

    const { data, error, mutate } = useSWR<Challenge[]>(
        shoudFetch
            ? `/users/${authData?.userUid}/challenges?type=${type}&exam=${userData?.examId}&workspace=${userData?.appWorkspace}/${type}`
            : null,
        fetcher,
    )

    const loading = !data && !error
    return {
        data,
        loading,
        error,
        mutate,
    }
}

export const useChallengeRankings = () => {
    const { userData } = React.useContext(UserContext)
    const { authData } = React.useContext(AuthContext)
    const workspaceId = userData?.appWorkspace || ''
    const examId = userData?.examId

    const fetcher = () => TestySdk.getChallengeRankings(examId || '', workspaceId, authData?.userUid || '')

    const { data, error } = useSWR<RankInfo>(
        `/users/${authData?.userUid}/challenge-rankings?exam=${examId}&workspace=${workspaceId}`,
        fetcher,
    )

    const loading = !data && !error

    return { data, loading, error }
}

export const useChallenge = (challengeId: string): SWRResponse<Challenge | undefined> => {
    const { userData } = React.useContext(UserContext)
    const { authData } = React.useContext(AuthContext)

    const shouldFetch = userData
    const fetcher = () => TestySdk.getChallenge(authData?.userUid || '', challengeId)

    const { data, error } = useSWR<Challenge>(
        shouldFetch ? `/users/${authData?.userUid}/challenges/${challengeId}` : null,
        fetcher,
    )

    const loading = !data && !error

    return { data, loading, error }
}
